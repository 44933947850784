import { Component, OnChanges, Input, EventEmitter, Output } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { SettingService } from '../../_services/setting.service';
import { PermissionService } from '../../_services/permission.service';
import { DateTimeService } from '../../_services/datetime.service';
import { ListUtility } from '../../_utilities/list.utility';


@Component({
  selector: 'swe-timelinegrid',
  templateUrl: './timelinegrid.component.html'
})
export class TimelineGridComponent implements OnChanges {

  @Input() data: any[] = [];
  @Input() more: boolean = false;
  @Input() dofilter: boolean = false;
  @Input() notifyemail: boolean = false;
  @Input() notifysms: boolean = false;
  @Input() all: boolean = false;
  @Input() allchecked: boolean = false;
  @Input() holidays: any[] = [];
  @Input() menucollapsed: boolean = false;
  @Input() changedSettings: any = {}; //Incoming GridSettings
  @Output() moreChange = new EventEmitter<any>();
  @Output() groupbyChange = new EventEmitter<any>();
  @Output() sumoptionsChange = new EventEmitter<any>();
  @Output() settingsChange = new EventEmitter<any>();
  @Output() rowToggleChanged = new EventEmitter<any>();

  private _rows: any[] = [];
  private _settings: GridSettings;
  private _hourstartdefault;
  private _hourenddefault;
  private _allchecked: boolean = false;
  private _scrollup = false;
  private _scrolldown = false;
  private _listUtility = new ListUtility();
  constructor(
    public languageService: LanguageService,
    public settingService: SettingService,
    private permissionService: PermissionService,
    private dateTimeService: DateTimeService
  ) {

  }

  ngOnInit() {
    this._listUtility.datacheckedname = "selected";
    this._listUtility.disableMultiple = true;
  }

  ngOnChanges() {
    if (this._allchecked != this.allchecked) {
      this._allchecked = this.allchecked;

      let noCheckedRows = 0;
      this.data.forEach(groupby => {
        noCheckedRows += groupby.checked ? 1 : 0;
      });

      let counter = 0;
      this.data.forEach(groupby => {
        groupby.Bookings.forEach((booking) => {
          booking.ismarked = (groupby.checked || noCheckedRows == 0) && this._allchecked;
          counter++;
        });
      });

      this.settings.shiftcheckcounter = (this._allchecked) ? counter : 0;
    }
    else {
      this._hourstartdefault = this.permissionService.permissions.CalendarDayStart;
      this._hourenddefault = this.permissionService.permissions.CalendarDayEnd;

      //if (this.data.length > 0) {
        this.load();
      //}
    }

    if (this.changedSettings) {

      Object.entries(this.changedSettings).forEach(([key, value]) => {
        this._settings[key] = value;
      });
      this.changedSettings = null;
    }
  }


  //Properties
  public get rows() {
    return this._rows;
  }
  public get settings() {
    return this._settings;
  }
  public get scrollup() {
    return this._scrollup;
  }
  public get scrolldown() {
    return this._scrolldown;
  }

  public get listUtility() {
    return this._listUtility;
  }



  //Methods
  public changedByMore() {
    this.moreChange.emit(true);
  }
  public changedByGroupBy(e) {
    this.groupbyChange.emit(e);
  }
  public changedBySumOptions(e) {
    this.sumoptionsChange.emit(e);
  }
  public changedBySettings(e) {
    if (e.reload) {
      this.listUtility.checkcounter = 0;
      this.rowToggleChanged.emit(false);
    }
    this.settingsChange.emit(e);
  }


  //Scroll
  public prepareScroll(e) {

    this._scrolldown = (e.clientY > (window.innerHeight - 100));

    this._scrollup = (e.clientY < 200);
    
  }
  public doScroll(e) {

    if (e.currentTarget.id == 'ScrollUp') {
      //ScrollUp
      let x = window.scrollX;
      let y = window.scrollY;
      window.scrollTo(x, y - 20);
    }
    else if (e.currentTarget.id == 'ScrollDown') {
      //ScrollDown
      let x = window.scrollX;
      let y = window.scrollY;
      window.scrollTo(x, y + 20);
    }
  }

  public resetScroll() {
    this._scrolldown = false;
    this._scrollup = false;
  }

  //Toggle row for request sending
  public toggleRow($event) {
    this.listUtility.toggle($event.row, $event.event);
    this.rowToggleChanged.emit(this.listUtility.ischecked);
  }
  //Functions
  private load() {

    if (this.data) {

      this.manageGrid();

      this._rows = this.data;
      this._listUtility.rows = this._rows;
    }

  }
  private manageGrid() {

    //Reset
    this.manageReset();

    //Hours
    this.manageHours();

    //Days                    
    this.manageDays();

    //Header
    this.manageHeader();

    //Cols
    this.manageCols();

    //Sum
    this.manageSum();

    //Availability
    this.manageAvailability();
  }
  private manageReset() {

    let emptyRowPinned: any = localStorage.getItem('emptyRowPinned');
    if (emptyRowPinned == null) { emptyRowPinned = true; }
    else if (emptyRowPinned == 'true') { emptyRowPinned = true; }
    else if (emptyRowPinned == 'false') { emptyRowPinned = false; }
    else { emptyRowPinned = true; }

    //Reset
    const marked = this._settings ? this._settings.marked : 0;
    const markedSerie = this._settings ? this._settings.markedSerie : 0;
    const markedgroup = this._settings ? this._settings.markedgroup : 0;
    this._settings = null;
    this._settings = {
      start: null,
      end: null,
      days: [],
      hours: [],
      dayfactor: 0,
      hourfactor: 0,
      showyear: false,
      showweekendcolor: false,
      showweek: false,
      showday: false,
      showhour: false,
      cols: [],
      headerheight: 60,
      today: 0,
      sum: [],
      notfilledsum: [],
      availablesum: [],
      availabilityheight: 0,
      resourcecheckcounter: this._rows.filter(r => r.checked).length,
      shiftcheckcounter: 0,
      marked: marked,
      markedgroup: markedgroup,
      detail: -1,
      emptyRowPinned: emptyRowPinned,
      markedSerie: markedSerie,
      emptyRowHeight: 0,
      emptyRowMaxHeight: 504,
      isdragged: false
    };
  }
  private manageHours() {
    let start = this._hourstartdefault;
    let end = this._hourenddefault;

    for (var i = start; i < end; i++) {
      if (i < 10) {
        this._settings.hours.push('0' + i.toString());
      }
      else {
        this._settings.hours.push(i.toString());
      }

      if (this.settingService.timeline.precision == -3) {
        //Half hour
        this._settings.hours.push("");
      }
      else if (this.settingService.timeline.precision == -4) {
        //Quarter
        this._settings.hours.push("");
        this._settings.hours.push("");
        this._settings.hours.push("");
      }
    }
  }
  private manageDays() {
    
    //DateTimes
    let start = new Date(this.settingService.start('booking'));
    let end = new Date(this.settingService.end('booking'));
    let days = (end.getTime() - start.getTime()) / (24 * 60 * 60 * 1000);

    let hourlength = this._settings.hours.length;
    if (this.settingService.timeline.precision == -3) { hourlength /= 2; } //Half hour
    if (this.settingService.timeline.precision == -4) { hourlength /= 4; } //Quarter

    if (Math.round(days) == 1 && hourlength < 24) {
      //Office Hours
      let starthour = parseInt(this._settings.hours[0]);
      let endhour = starthour + hourlength;

      start = new Date(start.getFullYear(), start.getMonth(), start.getDate(), starthour, 0);
      end = new Date(start.getFullYear(), start.getMonth(), start.getDate(), endhour, 0);
    }

    //Offsets
    let startoffset = start.getTimezoneOffset();
    let endoffset = end.getTimezoneOffset();

    //Factor
    if (startoffset != endoffset) {
      let diff = 1;
      if (startoffset > endoffset) {
        diff = -1;
      }
      this._settings.dayfactor = 1 - (diff / (hourlength * Math.round(days)));
      this._settings.hourfactor = 1 - (1 / hourlength);
    }
    else {
      this._settings.dayfactor = 1;
      this._settings.hourfactor = 1;
    }

    //Days
    let hiddendays = 0;
    let max = Math.round(days);
    for (var i = 0; i < max; i++) {
      let day = new Date(start.getTime() + i * 24 * 60 * 60 * 1000);

      //Offset
      let dayoffset = day.getTimezoneOffset();
      if (startoffset != dayoffset) {
        day = new Date(day.getTime() + (dayoffset - startoffset) * 60 * 1000);
      }

      if (i == 0 || this.manageHiddenDays(day, max)) {

        if (hiddendays > 0) {
          this._settings.days[this._settings.days.length - 1].width *= (1 + hiddendays);
          hiddendays = 0;
        }

        let holiday = this.isholiday(new Date(day.getFullYear(), day.getMonth(), day.getDate()));
        let holidayname = '';
        let holidayisred = false;
        if (holiday != null) {
          holidayname = holiday.Name;
          holidayisred = holiday.IsRed;
        }
        this._settings.days.push({
          date: new Date(day.getFullYear(), day.getMonth(), day.getDate()),
          left: i * 100 * this._settings.dayfactor / max,
          width: 100 / max,
          holiday: holidayname,
          isred: holidayisred,
          main: true
        });

        if (this.settingService.timeline.precision < -1) {
          //Hours or smallare
          let subs = 24;
          let subFactor = 1;
          if (this.settingService.timeline.precision == -3) { subFactor = 2; } //Half hour
          else if (this.settingService.timeline.precision == -4) { subFactor = 4; } //Quarter

          let day = this._settings.days[this._settings.days.length - 1];
          day.width /= (subs * subFactor);
          for (let j = 1; j < (subs * subFactor); j++) {
            let currentminute = 60 / subFactor * j;
            let holiday = this.isholiday(new Date(day.date.getFullYear(), day.date.getMonth(), day.date.getDate()));
            let holidayname = '';
            let holidayisred = false;
            if (holiday != null) {
              holidayname = holiday.Name;
              holidayisred = holiday.IsRed;
            }
            this._settings.days.push({
              date: new Date(day.date.getTime() + 1000 * 60 * currentminute),
              left: day.left + (j * day.width),
              width: day.width,
              holiday: holidayname,
              isred: holidayisred,
              main: false
            });
          }
          
        }
      }
      else {
        hiddendays++;
      }
    }

    //HiddenDays
    if (hiddendays > 0) {
      this._settings.days[this._settings.days.length - 1].width *= (1 + hiddendays);
    }

    //Show
    this._settings.showyear = max > 729;
    this._settings.showweekendcolor = max < 120;
    this._settings.showweek = max < 120;
    this._settings.showday = max < 60;
    this._settings.showhour = (max == 1);

    //Today
    let today = new Date();
    if (start < today && today < end) {
      this._settings.today = 100 * ((today.getTime() - start.getTime()) / (end.getTime() - start.getTime()));
    }

    //Sum Option (Graph)
    if (this._settings.showhour) {
      this.settingService.timeline.sumoptions = 0; //Auto
    }
    else {
      this.settingService.timeline.sumoptions = this.permissionService.permissions.ChartObjectDefault ? 1 : 0;
    }

    //Start and End
    this._settings.start = start;
    this._settings.end = end;
  }
  private manageCols() {
    if (this._settings.showhour) {
      let day = this._settings.days[0];
      let currenthour = this._hourstartdefault;

      let hourlength = this._settings.hours.length;
      let subFactor = 1;
      if (this.settingService.timeline.precision == -3) { hourlength /= 2; subFactor = 2; } //Half hour
      else if (this.settingService.timeline.precision == -4) { hourlength /= 4; subFactor = 4; } //Quarter

      for (var i = 0; i < hourlength; i++) {

        let left = i * 100 * this._settings.hourfactor / hourlength;
        let width = 100 / hourlength;

        let holiday = this.isholiday(new Date(day.date.getFullYear(), day.date.getMonth(), day.date.getDate()));
        let holidayname = '';
        let holidayisred = false;
        if (holiday != null) {
          holidayname = holiday.Name;
          holidayisred = holiday.IsRed;
        }
        this._settings.cols.push({
          date: new Date(day.date.getTime() + 1000 * 60 * 60 * currenthour),
          left: left,
          width: width / subFactor,
          holiday: holidayname,
          isred: holidayisred
        });

        for (let j = 1; j < subFactor; j++) {
          let currentminute = 60 / subFactor * j;
          let holiday = this.isholiday(new Date(day.date.getFullYear(), day.date.getMonth(), day.date.getDate()));
          let holidayname = '';
          let holidayisred = false;
          if (holiday != null) {
            holidayname = holiday.Name;
            holidayisred = holiday.IsRed;
          }
          this._settings.cols.push({
            date: new Date(day.date.getTime() + 1000 * 60 * 60 * currenthour + 1000 * 60 * currentminute),
            left: left + (j * (width / subFactor)),
            width: width / subFactor,
            holiday: holidayname,
            isred: holidayisred
          });
        }

        currenthour++;
      }
    }
    else {
      this._settings.cols = this._settings.days;
    }
  }
  private manageHeader() {
    this._settings.headerheight = 60;

    if (!this._settings.showweek) {
      //vm.headerheight -= 20;
    }
    if (!this._settings.showday) {
      this._settings.headerheight -= 20;
    }
    if (!this._settings.showhour) {
      this._settings.headerheight -= 20;
    }

    //Min 
    if (this._settings.headerheight < 40) {
      this._settings.headerheight = 40;
    }
  }
  private manageSum() {
    if (this._settings.showhour) {
      for (var i = 0; i < this._settings.hours.length; i++) {
        this._settings.sum.push(0);
        this._settings.notfilledsum.push(0);
        this._settings.availablesum.push(0);
      }
    }
    else {
      for (var i = 0; i < this._settings.days.length; i++) {
        this._settings.sum.push(0);
        this._settings.notfilledsum.push(0);
        this._settings.availablesum.push(0);
      }
    }

    //GroupSum
    this.data.forEach((row) => {
      row.sum = [...this._settings.sum]; //Copy
      row.notfilledsum = [...this._settings.notfilledsum]; //Copy
      row.availablesum = [...this._settings.availablesum]; //Copy
    });
  }
  private manageAvailability() {

    //Height
    let availablerows = this.permissionService.permissions.AvailabilityRows;
    if (typeof availablerows == "undefined" || availablerows == null) {
      availablerows = 1;
    }
    if (this.settingService.timeline.collapserow) {
      this._settings.availabilityheight = 20 * 1;
    }
    else {
      this._settings.availabilityheight = 20 * availablerows;
    }
  }
  private manageHiddenDays(day, max) {

    //Precision
    if (this.settingService.timeline.precision < 0) {
      //Day
      return true;
    }
    else if (this.settingService.timeline.precision == 1) {
      //Week
      return (day.getDay() == 1);
    }
    else if (this.settingService.timeline.precision == 2) {
      //Month
      return (day.getDate() == 1);
    }

    //Auto
    return (max < 120 || day.getDate() == 1);
  }
  private isholiday(date) {
    let res = null;

    for (var i = 0; i < this.holidays.length; i++) {
      let datewithoutTimeZone = this.dateTimeService.format(new Date(this.holidays[i].Date), 'yyyy-MM-dd');
      let holiday = new Date(datewithoutTimeZone);
      if (date.getTimezoneOffset() != 0) {
        holiday = new Date(holiday.getTime() + (date.getTimezoneOffset() * 60 * 1000));
      }

      if (holiday.getTime() == date.getTime()) {
        res = this.holidays[i];
        break;
      }
    }

    return res;
  }
}

export interface GridSettings {
  start: Date;
  end: Date;
  days: any[];
  hours: any[];
  dayfactor: number;
  hourfactor: number;
  showyear: boolean;
  showweekendcolor: boolean;
  showweek: boolean;
  showday: boolean;
  showhour: boolean;
  today: number;
  headerheight: number;
  cols: any[];
  sum: any[];
  notfilledsum: any[];
  availablesum: any[];
  availabilityheight: number;
  resourcecheckcounter: number;
  shiftcheckcounter: number;
  marked: number;
  markedgroup: number;
  detail: number;
  emptyRowPinned: boolean;
  markedSerie: number;
  emptyRowHeight: number;
  emptyRowMaxHeight: number;
  isdragged: boolean;
}
